import React from 'react';

import Meta from '../components/meta';

import { StaticImage } from "gatsby-plugin-image";

import Contents from '../components/contents';

import SubPageNav from '../components/subPageNav';

const Vision = (props) =>{
    const width = 384

    return(
        <>

            <Meta
                title="ビジョン"
                description="アクロスソリューションズは何が得意なのか。どこへ向かおうとしているのか。私たちは車や不動産などを提供している企業ではありません。私たちは「電子商取引」が事業ドメインであり、電子商取引に係る「プロフェッショナル」です。各種業界の商取引にはまだまだアナログが残り、国内市場の約80％がアナログによる業務取引を行っています。アナログが故に様々な業務プロセスにおいて入力業務や確認業務などの無理無駄が発生し、業務効率化が図れないだけではなく、新規取引先増加と業務量増加が正比例し、いつまで経っても経常利益率を上げることができません。私たちは受発注だけにとらわれず、企業間取引という「軸」をぶらさず、企業間取引における「しくみ」を提供し続け、お客様の「成功」をサポートしていきます。私たちは「電子商取引」を変えていきます。"
            />

            <Contents>

                <div className="c-sub-header">
                    <SubPageNav static="vision" />

                    <em className="c-sub-header--eng u-josef">
                    VISION
                    </em>

                    <h1 className="c-sub-header--title">
                    しくみで業務を改善する
                    </h1>
                </div>



                <div className="p-vision">

                    <div className="p-vision-box">
                        <div className="p-vision-box-cnt u-align-center u-margin-bottom__2">
                            <StaticImage
                                src="../images/vision_logo.png"
                                className="p-vision-box-cnt--image"
                                alt="Change"
                                placeholder="blurred"
                                quality="100"
                                width={width}
                            />
                        </div>

                        <p className="p-vision-box--text">
                        生活や暮らしの中には様々なしくみがあります。<br />
                        それはQOLを向上させるもの、新しい体験を味わうためのものなど様々です。<br />
                        その工程をより効率的で簡単にするためのしくみをアクロスソリューションズは提供します。<br />
                        既に存在しているしくみ、新しくご提案するしくみのいずれに対しても共に考え、<br />
                        誰もが分かりやすく利用することができるシステムを、私たちは提供し続けていきます。<br />
                        今を超えた、新しいしくみをより簡単で直感的に利用できるように<br />
                        常に挑戦し続けていきます。<br />
                        それが私たちアクロスソリューションズのビジョンです。
                        </p>
                    </div>

                    <div className="p-vision-bg"></div>

                </div>

            </Contents>
        </>
    )
}

export default Vision;


